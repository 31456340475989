import $ from 'jquery';
import 'datatables.net';
import Loadeer from "loadeer";
import {BuildFilteredSwiper, setNextPagesArray} from "./swiper";

export const getTrHeight = () => {
    const trData = document.querySelectorAll('tr')[0].getBoundingClientRect();
    const trHeight = trData.height;
    return trHeight;
}

export  const loadImagesAsync = (selector, parent) =>{
    return new Promise((resolve, reject) => {
        if (window.loadeerInstance) {
            window.loadeerInstance.observe();
            resolve(); // Immediately resolve if already initialized
        } else {
            let images = parent.querySelectorAll(selector);
            let loadedCount = 0;

            window.loadeerInstance = new Loadeer(selector, {
                root: parent,
                rootMargin: window.innerHeight / 2 + "px",
                threshold: 0,
                onLoaded: (image) => {
                    image.parentElement.classList.add('loaded');
                    loadedCount++;
                    console.log(loadedCount);
                    if (loadedCount === 100) {
                        resolve(); // Resolve when all images are loaded
                    }
                }
            });
            window.loadeerInstance.observe();
        }
    });
};


export const fadeInRows = async (delay, parentEl, isImage) => {

    parentEl.forEach((child, index) => {
        if(delay != 0){
        setTimeout(() => {
            child.classList.add('faded-in');
        }, delay * index);
        }else{
            child.classList.add('faded-in');
        }
    });
    if (isImage) {
        loadImagesAsync('[data-lazyload]', document.querySelector('.main'));
    }
};

function calculateImgHeight(tr){
    const ratio = tr.dataset.ratio;
    const coverContainerWidth = document.querySelector(".table-wrapper .cover-inner").clientWidth;
    var imgHeight;

    if(tr.dataset.orientation === "portrait"){
        imgHeight = coverContainerWidth * 0.70 / ratio;
    }else if (tr.dataset.orientation === "square"){
        imgHeight = coverContainerWidth * 0.85;
    }else{
        imgHeight = coverContainerWidth / ratio;
    }
    return imgHeight;
}

export const destroyDataTable = () =>{
    window.table?.destroy();
    window.table = null;
    window.removeEventListener('resize', SetTableMaxHeight);
}

const SetTableMaxHeight = () => {
    console.log('resize');
    const tableBody = document.querySelector('table');
    if (tableBody) {
        const tableScrollHead = document.querySelector('.dataTables_scrollHead');
        const tableScrollBody = document.querySelector('.dataTables_scrollBody');

        var tableScrollHeadHeight = tableScrollHead.offsetHeight;

        var tablemaxHeightEl = document.querySelector('.table-wrapper');
        var tablemaxHeight = tablemaxHeightEl.offsetHeight - tableScrollHeadHeight + 5;
        tableScrollBody.style.maxHeight = tablemaxHeight + "px";
    }

}

export const dataTable = (saveState) => {

    if(window.table){
       destroyDataTable();
    }
    const rows = document.querySelectorAll('tbody tr');
    const tableBody = document.querySelector('table');
    const coverContainer = document.querySelector(".cover .cover-inner");


    window.table = $('#example').DataTable({
        scrollResize: true,
        paging: false,
        searching: false,
        scrollY: "50vh - 3em",
        info: false,
        responsive: true,
        order: [[ 3, 'desc' ], [ 0, 'asc' ]],
        initComplete: function () {
            SetTableMaxHeight(tableBody);
            const rows = document.querySelectorAll('tbody tr');
            fadeInRows(15, rows, false);
            const scrollContainer = document.querySelector('.dataTables_scrollBody');
            scrollContainer.addEventListener('scroll', () =>{
                rows.forEach((row) => {
                    row.classList.remove('hovered');
                    row.classList.remove("not-hovered");
                    coverContainer.classList.remove('fade-in');
                });
            });
            scrollContainer.setAttribute("tabindex", "-1");
            scrollContainer.focus({ focusVisible: true });
            scrollContainer.scrollTo({
                top: 0,
                left: 0,
            });
            setNextPagesArray('archives');

            const filterMenuItem = document.querySelectorAll('.filter-menu li a');

            filterMenuItem.forEach((filter) => {
                filter.addEventListener('click', (e) => {
                    setTimeout(function (){
                        const TableScrollContainer = document?.querySelector('.dataTables_scrollBody');
                        TableScrollContainer?.scrollTo(0,0);
                        SetTableMaxHeight(tableBody);
                    }, 250);
                });
            });
        }
    });




        window.addEventListener('resize', SetTableMaxHeight);

        var handleOrderEvent = true;
         window.table.on('order', function (e) {
            if (!handleOrderEvent) return; // Quitte si l'événement ne doit pas être traité

            var order = table.order();
                var orderCol = order[0][0];
                var ordering = order[0][1];
                handleOrderEvent = false; // Désactive l'événement 'order'
                table.order([[orderCol, ordering], [0, 'asc']]).draw();
                handleOrderEvent = true; // Réactive l'événement 'order'
            setNextPagesArray('archives');
        });

    const tbody = document.getElementsByTagName('tbody')[0];

    rows.forEach((row) => {
        row.addEventListener('mouseenter', () => {

            if (!row.hasAttribute('disabled')) {
                rows.forEach((row) => {
                    row.classList.remove('hovered');
                    row.classList.add("not-hovered");
                });

                row.classList.add('hover');
                row.classList.remove("not-hovered");
            }


            const coverImg = row.dataset.cover;
            const scrollContainer = document.querySelector('.dataTables_scrollBody');
            const scrollContainerScrollTop = scrollContainer.scrollTop;
            var imageContainerHeight = document.querySelector(".table-wrapper .cover").clientHeight;
            const headerHeight = document.querySelector('.dataTables_scrollHead').clientHeight;
            var posY = row.offsetTop - scrollContainerScrollTop;

            if (coverImg !== undefined) {
                const imgHeight = calculateImgHeight(row);
                coverContainer.innerHTML = coverImg;
                const img = coverContainer.querySelector('picture');
                if(posY > (imageContainerHeight - (imgHeight + headerHeight + 20))) {
                    const distance = posY - imgHeight;
                    img.style.position = "relative";
                    img.style.top = distance + headerHeight + row.clientHeight - 2 +"px";
                }else{
                    img.style.position = "relative";
                    img.style.top = posY + headerHeight + 2 +"px";
                }
                coverContainer.classList.add('fade-in');
            }
        })
        row.addEventListener('mouseleave', () => {
            rows.forEach((row) => {
                row.classList.remove('hovered');
                row.classList.remove("not-hovered");
                coverContainer.classList.remove('fade-in');
            });
        })

        row.addEventListener('click', () => {
            if (!row.hasAttribute('disabled')) {
                row.classList.add('black-clicked');
                rows.forEach((row) => {
                    row.classList.add('grey-clicked');
                });
            }
        });
    });
}


dataTable();






