import {screenSaver} from "./_modules/screensaver";

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Loadeer from 'loadeer';

/*const instance = new Loadeer('[data-lazyload]', {
  onLoaded: (element) => {
    element.addEventListener('load', ({ target }) => {
      target.classList.add('has-loaded');
    })
  }
})
instance.observe()*/


import './_modules/ajaxLoad';
import './_modules/overlay';
import './_modules/datatable';


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
});