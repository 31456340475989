import {setColors} from "./swiper";

const overlay = document.querySelector('.home--overlay');
const header = document.getElementsByTagName('header')[0];
const popSwiper = document.querySelector('.pop-swiper');

overlay?.addEventListener("click", function (){
    document.body.classList.add('slide-down');
    header.classList.add('is-sliding');

    setTimeout(function(){
        setColors("header");
    }, 600);

    document.body.addEventListener('animationend', function (){
            header.classList.add('slided-down');
            header.classList.remove('is-sliding');

           /* if(!popSwiper.classList.contains('faded-in')){
             /!*   window.history.pushState(null, "", "/work");*!/
            }

            /!*const siteTitle = document.title;
            const docTitle = 'Selection';

            if(!siteTitle.includes('| Selection')) {

                const newTitle = siteTitle + " | " + docTitle;

                document.title = newTitle
            }*!/*/

        if(window.swiper){
            window.swiper.autoplay.start();
        }

    });

});

if(overlay){
    setTimeout(function (){
        overlay.click();
    }, 2000)
}




