import {
    BuildFilteredSwiper,
    Carousel,
    projectCarousel,
    ResetColors,
    resizePlaceholders,
    setNextPagesArray
} from "./swiper";
import {dataTable, destroyDataTable, fadeInRows} from "./datatable";
import {filters, ResetFilter, SetFilter} from "./filters";
import {addTargetBlank} from "./website";


export const updateHistory = (targetPageId, url, data) => {

    const siteTitle = data["siteTitle"];
    const docTitle = data["title"];
    var newTitle = "";

    if (data['title'] === 'Home') {
        newTitle = siteTitle;
    } else {
        newTitle = siteTitle + " | " + docTitle;
    }


    document.title = newTitle;

    const newState = {page: targetPageId};
    const newUrl = url;
    history.pushState(newState, newTitle, newUrl);
}

export const updatePopSwiper = (targetPageId) => {
    const popSwiper = document.querySelector('.pop-swiper');
    const popSwiperContainer = popSwiper.querySelector('.pop-swiper-inner');
    const popSwiperTitleEl = popSwiper.querySelector('.pop-swiper-title h1');

    var url = targetPageId;
    var targetUrl = url + ".json";

    fetch(targetUrl)
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            const html = data['popswiper'];
            const title = data['title'];
            const template = data['template'];

            updateHistory(targetPageId, url, data);

            popSwiperTitleEl.innerHTML = title;
            popSwiperContainer.innerHTML = html;
            projectCarousel();
        });
}

document.body.addEventListener("click", (e) => {
    const {target} = e;
    if (target.matches('.load-page')) {

        e.preventDefault();
        e.stopPropagation();

        const main = document.querySelector('.main');
        const header = document.querySelector(".header");
        const menu = document.querySelector(".menu-toggle.collapsible");
        var rep = document.querySelector('.header .site-title');

        /*menu.nextElementSibling.style.display = null;
        menu.classList.remove('active');
        header.classList.remove('menu-active');
        rep.style.opacity = null;*/


        const menuLinks = document.querySelectorAll('.load-page');
        menuLinks.forEach((link) => {
            link.classList.remove('active');
        });

        target.classList.add('active');

        var targetPageId = target.dataset.page
        var activePage = document.body.getAttribute("id");
        var url = target.dataset.page;
        var regexPattern = /\/([^\/]+)$/; // Regular expression pattern
        var pageId = url.match(regexPattern);
        if (pageId && pageId[1]) {
            pageId = pageId[1]; // Extracted word from the regex match
        } else {
            pageId = null; // Set pageId to null if no match is found
        }


        var targetUrl = url + ".json"


        document.body.classList.add('is-loading');

        ResetColors('header');

        fetch(targetUrl)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const html = data['html'];
                const template = data['template'];

                updateHistory(targetPageId, url, data);
                setTimeout(function () {
                    ResetFilter();
                    buildPage(data);
                }, 100);


            });
    }
    if (target.matches('.load-swiper')) {
        e.preventDefault();
        e.stopPropagation();

        if (target.hasAttribute('disabled')) {
            return false;
        } else {

            const popSwiper = document.querySelector('.pop-swiper');
            const popSwiperContainer = popSwiper.querySelector('.pop-swiper-inner');
            const popSwiperTitle = popSwiper.querySelector('.pop-swiper-title');
            const popSwiperTitleEl = popSwiper.querySelector('.pop-swiper-title h1');

            if (window.swiper) {
                window.swiper.disable();
            }

            ResetColors('.pop-swiper-header');

            document.body.classList.add('is-loading');

            const MenuLinks = document.querySelectorAll(".page-menu li a");

            MenuLinks.forEach((link) => {
                link.classList.remove('active');
            });


            if (target.hasAttribute('disabled')) {
                return false;
            } else {

                var hasTitle = target.dataset.title ?? true;

                if (hasTitle === "none") {
                    popSwiperTitle.style.display = "none";
                } else {
                    popSwiperTitle.style.display = null;
                }

                var targetPageId = target.dataset.page;
                var url = targetPageId;
                var targetUrl = url + ".json";

                fetch(targetUrl)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        const html = data['popswiper'];
                        const title = data['title'];
                        const template = data['template'];
                        const index = target.dataset.pos;
                        const handleNext = target.dataset.next;
                        const fromPage = document.body.dataset.template;

                        document.body.setAttribute("id", data['uid']);
                        document.body.dataset.template = template;
                        updateHistory(targetPageId, url, data);
                        popSwiperTitleEl.innerHTML = title;
                        popSwiperContainer.innerHTML = html;
                        projectCarousel(data, fromPage, handleNext);
                    });
            }
        }
    }
});

var targetUrl = window.location.pathname + ".json";
var targetPageId = window.location.pathname;
var url = window.location.pathname;

if (window.location.pathname === "/") {
    targetUrl = 'home' + ".json";
}

fetch(targetUrl)
    .then(function (response) {
        return response.json();
    })
    .then(function (data) {
        const html = data['html'];
        const template = data['template'];

        updateHistory(targetPageId, url, data);
        buildPage(data);


    });

window.addEventListener('popstate', function (event) {

    if (event.state !== null && event.state.page !== "/") {
        var targetPageId = event.state.page;
    } else {
        var targetPageId = '/selection';
    }
    var url = targetPageId;
    var targetUrl = url + ".json";
    // You can use event.state to get the state data you pushed in pushState
    // and load the appropriate page based on that data.
    if (targetPageId) {
        fetch(targetUrl)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const html = data['html'];
                const template = data['template'];
                updateHistory(targetPageId, url, data);
                setTimeout(function () {
                    buildPage(data);
                    if (template === 'project') {
                        updatePopSwiper(targetPageId);
                        const popSwiperTitle = document.querySelector('.pop-swiper-title');

                        popSwiperTitle.classList.remove('fade-out');
                    } else {
                        const popSwiper = document.querySelector('.pop-swiper');
                        const popSwiperTitle = document.querySelector('.pop-swiper-title');

                        popSwiper.classList.remove('faded-in');
                        popSwiperTitle.classList.remove('fade-out');
                    }
                }, 0);
            });
    }
});


const buildPage = (data) => {

    const menuLinks = document.querySelectorAll('.load-page');

    const header = document.querySelector('header');
    header.style.setProperty("--color-link", null);
    header.style.setProperty("--color-hover", null);

    if (data['uid'] !== 'home' && data['template'] !== 'project') {
        menuLinks.forEach((link) => {
            if (link.dataset.page === '/' + data['uid']) {
                link.classList.add('active');
            } else {
                link.classList.remove('active');
            }
        });
    }

    const main = document.querySelector("main");
    const popSwiper = document.querySelector('.pop-swiper-inner');
    const backgroundColor = data["backgroundColor"];

    const html = data["html"];
    const containerClass = data["container"];
    const container = document.querySelector(containerClass);

    const scrollContainer = document.querySelector(".main");
    scrollContainer.scrollTo(0, 0);

    document.documentElement.style.setProperty("--background-color", backgroundColor);
    main.innerHTML = html;
    initPage(data);
    document.body.classList.remove('is-loading');

    console.log("Builded");
}

const initPage = (data) => {

    addTargetBlank();
    const uid = data["uid"];
    const template = data["template"];

    document.body.dataset.template = template;
    document.body.setAttribute("id", uid);

    if (window.swiper && template !== 'project') {
        window.swiper?.destroy();
        window.swiper = null;
    }else if(template === 'project'){
        window.swiper?.disable();
    }


    SetFilter();

    const filteredContainer = document.querySelector('#filtered-container');

    if (filteredContainer != null) {
        filteredContainer.remove();
    }

    destroyDataTable();

    switch (template) {
        case 'home':
            setNextPagesArray('home');
            Carousel();
            break;
        case 'project':
            Carousel()
            projectCarousel()
            break;
        case 'archives':
            window.slideIndex = null;
            dataTable();
            filters();
            break;
        case 'about':
            Carousel();
            break;
        case 'selection':
            setNextPagesArray('home');
            Carousel();
            break;
        case 'overview':
            const parentEl = document.querySelectorAll('.overview--grid-item');
            let delay = document.documentElement.classList.contains('touch') ? 0 : 10;
            fadeInRows(delay, parentEl, true);
            filters();
            BuildFilteredSwiper();
            break;
        default:

    }

    window.addEventListener('resize', resizePlaceholders);
}


