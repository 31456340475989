import {BuildFilteredSwiper, ResetColors, setNextPagesArray} from "./swiper";

export const filters = () => {

    const filterMenuItem = document.querySelectorAll('.filter-menu li a');
    const main = document.querySelector('.main');

    filterMenuItem.forEach((filter) => {
        filter.addEventListener('click', (e) => {
            e.preventDefault();
            const categoryClass = '.' + filter.getAttribute('href');
            const category = filter.getAttribute('href');
            const items = main.querySelectorAll('.filter-item');
            const selectedItems = main.querySelectorAll(categoryClass)
            const filterID = filter.getAttribute('href');
            const bodyTemp = document.body.dataset.template;
            const filteredContainer = document.querySelector('#filtered-container');
            const filterMenu = document.querySelector('.filter-menu');


            filterMenuItem.forEach((filter) => {
                if (e.target === filter) {
                    filter.classList.add('active');
                } else {
                    filter.classList.remove('active');
                }
            });

            if (bodyTemp === 'overview') {
                var url = "/filtered/" + category;
                var targetUrl = url + ".json";

                if(window.filteredSlider){
                    window.filteredSlider.disable();
                }

                document.body.classList.remove('filtered-visible');

                const header = document.querySelector(".site--header");

                const filteredContainer = document.getElementById('filtered-container');

                filterMenu.style.setProperty('opacity', '1', 'important');

                if(filteredContainer.classList.contains('faded-in')){
                    setTimeout(function (){
                        filteredContainer.classList.remove('faded-in');
                        fetch(targetUrl)
                            .then(function (response) {
                                return response.json();
                            })
                            .then(function (data) {
                                BuildFilteredSwiper(category);
                                ResetColors('header');
                                if(window.filteredSlider){
                                    window.filteredSlider.enable();
                                }
                            });
                    }, 150);
                }else{
                    fetch(targetUrl)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (data) {
                            BuildFilteredSwiper(category);
                            if(window.filteredSlider){
                                window.filteredSlider.enable();
                            }
                        });
                }


            }else{
                filterMenu.style.setProperty('opacity', '1', 'important');
            }


            document.body.classList.add("is-loading", "filter-loading");

            var lastItem

            setTimeout(function () {

                items.forEach((item) => {
                    item.classList.remove('last');
                    if (!item.classList.contains(category)) {
                        item.style.display = "none";
                        item.classList.add("hide");
                        /* item.querySelector('.load-swiper').dataset.page =  null;*/
                    } else {
                        item.style.display = null;
                        item.classList.remove("hide");
                        lastItem = item;
                    }

                });
                lastItem.classList.add('last');
                if(bodyTemp === "overview"){
                    main.scrollTo(0,0);
                }
                const header = document.querySelector("header");
                document.body.classList.remove("is-loading", "filter-loading");
                filterMenu.style.removeProperty('opacity', '1', 'important');
                setNextPagesArray('archives');
            }, 300);

        });

    });
}

export const ResetFilter = () => {

    const filterMenuItem = document.querySelectorAll('.filter-menu li a');

    filterMenuItem.forEach((filter) => {
        filter.classList.remove('active');
    });
}

export const SetFilter = () => {

    const filterMenuItem = document.querySelectorAll('.filter-menu li a');

    filterMenuItem.forEach((filter) => {
        if (filter.getAttribute("href") === "all") {
            filter.classList.add('active');
        } else {
            filter.classList.remove('active');
        }

    });
}
