import {screenSaver} from "./screensaver";

export const onLoading = () => {
  addClassesToHTML();
  addTargetBlank();
  addVHValue();
};

const addClassesToHTML = () => {
  const isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iP(ad|hone)/i);
  const webkit = !!ua.match(/WebKit/i);
  const isiOS = iOS && webkit && !ua.match(/CriOS/i);
  const isFirefox = !!ua.match(/Firefox/i);
  const touch = isTouch ? "touch" : "no-touch";
  const os = isiOS ? "ios" : "no-ios";
  const Firefox = isFirefox ? "is-moz" : "no-moz";
  document.documentElement.classList.remove("touch", "no-touch", "ios", "no-ios");
  document.documentElement.classList.add(touch, os, Firefox);
  if(touch === 'no-touch'){
    screenSaver();
  }
};

export const addTargetBlank = () => {
  const links = document.querySelectorAll("a");
  links.forEach((link) => {
    if (link.target) {
      return;
    } else if (link.host !== window.location.host) {
      link.target = "_blank";
      link.rel = "noopener";
    } else {
      link.target = "_self";
    }
  });
};

const addVHValue = () => {

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const header = document.querySelector('header');
  const hh = header.offsetHeight;

  document.documentElement.style.setProperty("--hh", `${hh}px`);

  window.addEventListener("resize", () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const hh = header.offsetHeight;
    document.documentElement.style.setProperty("--hh", `${hh}px`);
  });
};
