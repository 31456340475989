import Swiper from "swiper";
import {
    Autoplay,
    EffectCreative,
    EffectFade,
    Keyboard,
    Manipulation,
    Mousewheel,
    Navigation,
    Virtual,
} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-creative';
import TouchSweep from 'touchsweep';
import {updatePopSwiper} from './ajaxLoad';

export const setNextPagesArray = (fromPage) => {
    function extractProjects(pages, isArchive = false) {
        const projects = [];
        const seenUrls = new Set(); // Utilisation d'un Set pour une vérification d'existence plus rapide

        pages.forEach((page, index) => {
            // Pour 'archives', l'index est simplement l'ordre d'itération, sinon il est extrait de dataset
            const projectIndex = isArchive ? index + 1 : parseInt(page.dataset.pos, 10);
            const url = isArchive ? page.dataset.page : page.dataset.url;

            // Ajouter le projet s'il est unique
            if (!seenUrls.has(url)) {
                seenUrls.add(url);
                projects.push({index: projectIndex, url});
            }
        });

        // Pour 'archives', pas besoin de trier puisque l'ordre d'itération est déjà correct
        if (!isArchive) {
            projects.sort((a, b) => a.index - b.index);
        }

        return projects;
    }

    if (['selection', 'home'].includes(fromPage)) {
        const pages = document.querySelectorAll(".default-swiper .swiper-slide:not(.swiper-slide-duplicate)[data-has-view-button='false']");
        window.uniqueProjects = extractProjects(pages);
        console.log(window.uniqueProjects);
    } else if (fromPage === 'archives') {
        const projects = document.querySelectorAll('.dataTables_scrollBody .filter-item:not(.hide):not([disabled])');
        window.uniqueProjects = extractProjects(projects, true);
        console.log(window.uniqueProjects);
    } else {
        window.uniqueProjects = [];
    }
}

export const ResetColors = (target) => {
    const header = document.querySelector(target);
    // Assumption: If the target is '.pop-swiper-header', use 'pop-up-swiper', otherwise use 'default-swiper'.
    const baseClass = target === ".pop-swiper-header" ? '.pop-up-swiper' : '.default-swiper';
    const legend = document.querySelector(`${baseClass} .swiper-legend`);
    const swiperContainer = document.querySelector(`${baseClass}.swiper-container`);

    // Créer une fonction pour simplifier la suppression des propriétés
    const removeColorProperties = (element) => {
        element?.style.removeProperty("--color-link");
        element?.style.removeProperty("--color-hover");
        element?.style.removeProperty("--color-background");
        element?.style.removeProperty("background-color");
    };

    // Appliquer la suppression des propriétés au header, à la légende et au conteneur du swiper
    [header, legend].forEach(removeColorProperties);
};

export const setColors = (target, context) => {
    var header = document.querySelector(target);
    const body = document.body;
    var legend;
    var activeSlide;
    var swiperContainer;
    let currentResizeListener = null;


    if (context === "pop-swiper") {
        legend = document.querySelector('.pop-up-swiper .swiper-legend');
        activeSlide = document.querySelector('.pop-swiper .swiper-slide-active');
        swiperContainer = document.querySelector('.pop-up-swiper.swiper-container');
    }else if(context === "filtered-swiper"){
        header = document.querySelector('.filtered-container .header');
        legend = document.querySelector('.pop-up-swiper.filtered-swiper .swiper-legend');
        activeSlide = document.querySelector('.pop-up-swiper.filtered-swiper .swiper-slide-active');
        swiperContainer = document.querySelector('.pop-up-swiper.filtered-swiper.swiper-container');
    }else {
        legend = document.querySelector('.default-swiper .swiper-legend');
        activeSlide = document.querySelector('.default-swiper .swiper-slide-active');
        swiperContainer = document.querySelector('.default-swiper.swiper-container');
    }

    function setStyles() {
        if (!activeSlide || !["home", "selection", "project", "overview"].includes(body.dataset.template)) {
            console.log('return');
            return;
        }

        const isSmallScreen = window.innerWidth < 1025;
        const color = isSmallScreen ? activeSlide.dataset.colorSm : activeSlide.dataset.colorMd;
        const hoverColor = isSmallScreen ? activeSlide.dataset.hoverColorSm : activeSlide.dataset.hoverColorMd;
        const backgroundColor = isSmallScreen ? getComputedStyle(activeSlide).getPropertyValue('--sm-bg-color') : getComputedStyle(activeSlide).getPropertyValue('--md-bg-color');

        // Appliquer les couleurs si les conditions sont remplies
        if (color && hoverColor && (document.body.classList.contains('slide-down') || body.dataset.template !== "home")) {
            header?.style.setProperty("--color-link", color);
            header?.style.setProperty("--color-hover", hoverColor);
            legend?.style.setProperty("--color-link", color);
            legend?.style.setProperty("--color-hover", hoverColor);
            swiperContainer.style.backgroundColor = backgroundColor;
            header?.style.setProperty("--color-background", 'transparent');
        }
    }

    setStyles();

   /* // Retirer l'écouteur précédent si présent
    if (currentResizeListener) {
        window.removeEventListener('resize', currentResizeListener);
    }

    // Définir et ajouter le nouvel écouteur
    currentResizeListener = () => {
        setStyles();
    };
    window.addEventListener('resize', currentResizeListener);*/
}

export const resizePlaceholders = () => {
    const imgPlaceHolders = document.querySelectorAll('.swiper-slide picture .placeholder');

    imgPlaceHolders?.forEach((placeholder) => {

        const imgContainer = placeholder.parentElement;


        var containerWidth = imgContainer.clientWidth;
        var containerHeight = imgContainer.clientHeight;
        var imageAspect = placeholder.getAttribute('width') / placeholder.getAttribute('height');

        var scaledWidth, scaledHeight;

        if (containerWidth / containerHeight > imageAspect) {
            scaledWidth = containerHeight * imageAspect;
            scaledHeight = containerHeight;
        } else {
            scaledWidth = containerWidth;
            scaledHeight = containerWidth / imageAspect;
        }

        if(window.innerWidth < 1024 && imgContainer.parentElement.dataset.sizeSm === 'default' && imgContainer.classList.contains('portrait')){

            const padLeft = Number(window.getComputedStyle(imgContainer, null).getPropertyValue('padding-left').replace('px', ''));
            const padTop = Number(window.getComputedStyle(imgContainer, null).getPropertyValue('padding-top').replace('px', ''));


            scaledWidth = containerWidth - (padLeft * 2);
            scaledHeight = scaledWidth / imageAspect;

            console.log(scaledWidth);
        }

        placeholder.style.height = scaledHeight + "px";
        placeholder.style.width = scaledWidth + "px";

    });

}

export const BuildFilteredSwiper = (category) => {
    const filteredContainer = document.getElementById('#filtered-container');

    var currentContainers = document.querySelectorAll('.filtered-container');

    currentContainers.forEach((container) => {
        container.remove();
    });


    if (!filteredContainer) {
        var newDiv = document.createElement('div');
        newDiv.setAttribute('id', 'filtered-container');
        newDiv.setAttribute('class', 'filtered-container');
        newDiv.classList.add('hidden');

        var swiperHeader = document.createElement('div');
        swiperHeader.setAttribute('class', 'pop-swiper-header');
        swiperHeader.innerHTML = null;

        document.body.appendChild(newDiv);

        const mainHeader = document.querySelector('.site--header .header').innerHTML;

        var header = {
            html: '<div class="filtered-swiper-header header">'+mainHeader+'</div>'
        };

        if (category == undefined) {
            category = "all";
        }
        var url = "/filtered/" + category;
        var targetUrl = url + ".json";

        const filteredContainer = document.querySelector('#filtered-container');

        fetch(targetUrl)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const html = data['html'];
                const mergedHtml = header.html + html;
                filteredContainer.innerHTML = mergedHtml;
                InitFilteredSwiper();
            });
    }
};


export const InitFilteredSwiper = (index) => {

    const filteredSlider = document.getElementById("filtered-container");
    if (filteredSlider) {
         window.filteredSlider = new Swiper('#filtered-container .pop-up-swiper', {
            modules: [Navigation, Manipulation, EffectFade, Autoplay, Keyboard, Virtual],
            keyboard: {
                onlyInViewport: true,
            },
            direction: "horizontal",
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            effect: 'creative',
            creativeEffect: {
                prev: {
                    // will set `translateZ(-400px)` on previous slides
                    opacity: 0,

                },
                next: {
                    // will set `translateX(100%)` on next slides
                    opacity: 0,
                },
            },
            lazyPreloadPrevNext: 2,
                lazyPreloaderClass: "placeholder",
            preventInteractionOnTransition: true,
            virtual: {
                enabled: true,
            },
            watchSlidesProgress: true,
            speed: 0,
            on: {
                init: function () {
                    resizePlaceholders();
                    ResetColors('header');

                    const area = document.querySelector('.pop-up-swiper');
                    const data = {
                        value: 1
                    };
                    const touchThreshold = 20;
                    var index = null;

                    const touchSweepInstance = new TouchSweep(area, data, touchThreshold);

                    area.addEventListener('swipeleft', event => {
                        event.preventDefault();
                        this.slideNext();
                    });
                    area.addEventListener('swiperight', event => {
                        event.preventDefault();
                        this.slidePrev();
                    });
                    area.addEventListener('swipeup', event => {
                        event.preventDefault();
                        this.slideNext();
                    });
                    area.addEventListener('swipedown', event => {
                        event.preventDefault();
                        this.slidePrev();
                    });

                },
                resize: function () {
                    this.update();
                    this.updateSize();
                    resizePlaceholders();
                },
                afterInit: function () {
                    resizePlaceholders();

                    const overviewItems = document.querySelectorAll('.overview-grid .filter-item');
                    const filteredContainer = document.querySelector('#filtered-container');

                    function getIndexExcludingHidden(item) {
                        // Get the parent element
                        const parent = item.parentElement;

                        // Convert the parent's children into an array
                        const children = Array.from(parent.children);

                        // Filter out the children with the class 'hide'
                        const visibleChildren = children.filter(child => !child.classList.contains('hide'));

                        // Get the index of the item among visible children
                        const index = visibleChildren.indexOf(item);

                        return index + 1;
                    }

                    var currentSwiper = this;

                    setTimeout(function () {
                        overviewItems.forEach((item) => {
                            item.addEventListener('click', (e) => {
                                setColors(".filtered-swiper-header", "filtered-swiper");
                                document.body.classList.add('filtered-visible');
                                item.classList.add('clicked');
                                e.preventDefault();
                                e.stopPropagation();
                                filteredContainer.classList.remove("hidden");
                                index = getIndexExcludingHidden(item);
                                currentSwiper.slideTo(index, 0);
                                currentSwiper.update();
                                filteredContainer.classList.add('faded-in');
                                setTimeout(function () {
                                    item.classList.remove('clicked');
                                }, 200);
                            });
                        });
                    }, 0);


                    const closeCross = document.querySelector('#filtered-container .close-cross');

                    function transitionEndHandler() {
                        setTimeout(function () {
                            filteredContainer.classList.add("hidden");
                        }, 300)
                        // Remove the event listener after it has been executed once
                        filteredContainer.removeEventListener('transitionend', transitionEndHandler);
                    }

                    document.body.addEventListener("click", (e) => {
                        const {target} = e;

                        if (target.matches('.load-page')) {
                            setTimeout(function () {
                                document.body.classList.remove('filtered-visible');
                                filteredContainer.classList.remove('faded-in');
                                ResetColors('header');
                            }, 150);
                            filteredContainer.addEventListener('transitionend', transitionEndHandler);
                        }
                    });

                    document.addEventListener('keyup', function (e) {

                        if (e.key === "Escape") {
                            ResetColors('header');
                            document.body.classList.remove('filtered-visible');
                            filteredContainer.classList.remove('faded-in');
                            filteredContainer.addEventListener('transitionend', transitionEndHandler);

                        }
                    });
                },
                slideChangeTransitionStart: function () {
                    setColors(".filtered-swiper-header", "filtered-swiper");
                    const filteredContainer = document.querySelector('#filtered-container');

                    resizePlaceholders();
                    this.update();
                    if(!filteredContainer.classList.contains('hidden')){
                       /* setColors("header", "filtered-swiper");*/
                    }
                    const activeSlide = document.querySelector('.swiper-slide-active');
                    const videos = document.querySelectorAll('.swiper-slide video');

                    if (videos) {
                        videos.forEach((video) => {
                            video.play();
                        });
                    }

                    if (activeSlide) {
                        const activeTitle = activeSlide.dataset.title;
                        const activeIndex = activeSlide.dataset.index;

                        const legendTitle = document.querySelector('.swiper-legend .slide-title');
                        const legendIndex = document.querySelector('.swiper-legend .slide-number');

                        legendTitle.innerHTML = activeTitle;
                        legendIndex.innerHTML = activeIndex;
                    }


                },
                slideChangeTransitionEnd: function () {
                    this.update();
                }
            }
        });

    }

}

export const projectCarousel = (SliderData, fromPage, HandleNext) => {

    const nextProjectLink = document.querySelector('.pop-swiper .next-project');

    const HandleLoadNextProject = () => {

        if (window.uniqueProjects && window.uniqueProjects.length !== 0) {

            if (window.uniqueProjects.length === 1) {
                nextProjectLink.style.display = 'none';
            } else {

                nextProjectLink.style.display = null;
                const curPage = window.location.pathname;
                const curPageIndex = window.uniqueProjects.findIndex(project => project.url === curPage);

                var nextPage;
                if (curPageIndex !== -1 && curPageIndex + 1 < window.uniqueProjects.length) {
                    nextPage = window.uniqueProjects[curPageIndex + 1];
                } else {
                    nextPage = window.uniqueProjects[0];
                }

                console.log(nextPage);


                nextProjectLink.dataset.page = nextPage.url;


                const popSwiperTitle = document.querySelector('.pop-swiper-title');
                const popSwiperTitleEl = document.querySelector('.pop-swiper-title h1');

                nextProjectLink.addEventListener('click', function () {
                    const popSwiperInner = document.querySelector('.pop-swiper-inner');
                    popSwiperInner.classList.add('fade-out');
                    popSwiperTitleEl.innerHTML = null;
                    popSwiperTitle.classList.remove('fade-out');


                    setTimeout(function () {
                        updatePopSwiper(nextPage.url);
                    }, 200);
                });
            }
        } else {
            nextProjectLink.style.display = 'none';
        }
    }


    var firstMove = false;

    const slider = document.querySelector(".swiper-container.pop-up-swiper");
    if (slider) {
        if (window.projectswiper) {
            window.projectswiper?.destroy();
            window.projectswiper = null;
        }
        window.projectswiper = new Swiper('.pop-up-swiper', {
            modules: [Navigation, Manipulation, EffectFade, Mousewheel, Keyboard],
            keyboard: {
                onlyInViewport: true,
            },
            direction: "horizontal",
            loop: true,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            lazyPreloadPrevNext: 2,
            lazyPreloaderClass: "placeholder",
            watchSlidesProgress: true,
            speed: 0,
            on: {
                init: function () {

                    const area = document.querySelector('.pop-up-swiper');
                    const data = {
                        value: 1
                    };
                    const touchThreshold = 20;

                    const touchSweepInstance = new TouchSweep(area, data, touchThreshold);

                    area.addEventListener('swipeleft', event => {
                        this.slideNext();
                    });
                    area.addEventListener('swiperight', event => {
                        this.slidePrev();
                    });
                    area.addEventListener('swipeup', event => {
                        event.preventDefault();
                        this.slideNext();
                    });
                    area.addEventListener('swipedown', event => {
                        event.preventDefault();
                        this.slidePrev();
                    });


                    const loadSwiper = document.querySelectorAll('.load-swiper');
                    const popSwiper = document.querySelector('.pop-swiper');
                    const popSwiperContainer = popSwiper.querySelector('.pop-swiper-inner');
                    const popSwiperTitle = popSwiper.querySelector('.pop-swiper-title');
                    const popSwiperTitleEl = popSwiper.querySelector('.pop-swiper-title h1');
                    const loadNext = document.querySelector('.next-project');
                    const closeCross = popSwiper.querySelector('.close-cross');


                    var SwiperInstance = this;

                    popSwiperContainer.classList.remove('fade-out');

                    document.addEventListener('keyup', HandleEscPopSwiper);

                    function HandleEscPopSwiper(e){
                        if (e.key === "Escape") {
                            popSwiper.classList.remove('faded-in');
                            const rows = document?.querySelectorAll('tbody tr');
                            rows.forEach((row) => {
                                row.classList.remove("grey-clicked");
                                row.classList.remove("black-clicked");
                            });

                           const isHome = document?.querySelector('.home-swiper.default-swiper');
                           if(isHome){
                               console.log('ishome2');
                               const menuLink = document.querySelector('.page-menu li a[data-temp="selection"]');
                               window.history.pushState({page: menuLink.dataset.page}, "hello", menuLink.dataset.page);
                               window.swiper.enable();
                               window.swiper.autoplay.start();
                               setColors("header", "home-swiper");
                               menuLink.classList.add('active');
                               const title = menuLink.innerHTML;
                               const newTitle = document.title.split('|')[0].trim() + " | " + title;
                               document.title = newTitle;
                           }else{
                               const menuLink = document.querySelector('.page-menu li a[data-temp="archives"]');
                               window.history.pushState({page: menuLink.dataset.page}, "hello", menuLink.dataset.page);
                               ResetColors('header');
                               menuLink.classList.add('active');
                               const title = menuLink.innerHTML;
                               const newTitle = document.title.split('|')[0].trim() + " | " + title;
                               document.title = newTitle;
                           }


                            document.removeEventListener('keyup', HandleEscPopSwiper);
                        }
                    }

                    document.body.addEventListener("click", (e) => {
                        const {target} = e;

                        if (target.matches('.load-page')) {


                            popSwiper.classList.remove('faded-in');
                            const rows = document?.querySelectorAll('tbody tr');

                            rows.forEach((row) => {
                                row.classList.remove("grey-clicked");
                                row.classList.remove("black-clicked");
                            });

                            document.body.classList.add('is-loading');
                            /*const currentPage = document.querySelector('.site-menu .page-menu .active').dataset.page;
                            fetch(currentPage + '.json')
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(function (data) {
                                    document.body.setAttribute('id', data['uid']);
                                    document.body.dataset.template = data['template'];
                                    updateHistory(currentPage, currentPage, data);
                                    data = null;
                                });*/

                            setTimeout(function () {
                                SwiperInstance.destroy();
                                popSwiperTitle.classList.remove('fade-out');
                                popSwiperTitleEl.classList.remove('show');
                                popSwiperTitleEl.innerHTML = null;
                                popSwiperContainer.innerHTML = null;
                            }, 300);
                        }

                    });

                    popSwiper.classList.add('faded-in');
                    setTimeout(function () {
                        document.body.classList.remove('is-loading');
                    }, 500);

                    if (popSwiperTitle.style.display === 'none') {
                        setTimeout(function () {
                            setColors("header", "pop-swiper");
                            slider.querySelector('.swiper-wrapper').classList.remove('hide');
                            slider.querySelector('.swiper-legend').classList.remove('hide');
                        }, 200);
                    } else {
                        slider.querySelector('.swiper-wrapper').classList.remove('hide');
                        setTimeout(function () {
                            setTimeout(function () {
                                popSwiperTitleEl.classList.add('show');
                                slider.querySelector('.swiper-legend').classList.remove('hide');
                            }, 0);
                            setTimeout(function () {
                                setColors("header", "pop-swiper");
                                popSwiperTitleEl.classList.remove('show');
                                popSwiperTitle.classList.add('fade-out');
                            }, 1500);
                        }, 500);
                    }


                    popSwiperTitle.addEventListener('animationend', setPointerEvent, false);

                    function setPointerEvent() {
                        setTimeout(function () {
                            popSwiperTitle.removeEventListener('animationend', setPointerEvent, false);
                        }, 500);

                    }

                },
                resize: function (){
                    setColors("header", 'pop-swiper');
                },
                afterInit: function () {

                    const activeSlide = slider.querySelector('.swiper-slide-active');

                    const activeTitle = activeSlide.dataset.title;
                    const activeIndex = activeSlide.dataset.index;

                    const legendTitle = slider.querySelector('.swiper-legend .slide-title');
                    const legendIndex = slider.querySelector('.swiper-legend .slide-number')

                    legendTitle.innerHTML = activeTitle;
                    legendIndex.innerHTML = activeIndex;

                    window.addEventListener('resize', resizePlaceholders);
                    resizePlaceholders();
                    HandleLoadNextProject();
                    ResetColors("header", "pop-swiper");
                },

                slideChangeTransitionStart: function () {
                    setColors("header", "pop-swiper");

                    const activeSlide = slider.querySelector('.swiper-slide-active');

                    const activeTitle = activeSlide.dataset.title;
                    const activeIndex = activeSlide.dataset.index;

                    const legendTitle = slider.querySelector('.swiper-legend .slide-title');
                    const legendIndex = slider.querySelector('.swiper-legend .slide-number')

                    legendTitle.innerHTML = activeTitle;
                    legendIndex.innerHTML = activeIndex;
                },
                slideChangeTransitionEnd: function () {

                }
            }
        });

    }


}

export const Carousel = () => {

    const homeslider = document.querySelector(".swiper-container.default-swiper");
    if (homeslider) {
        if (window.swiper) {
            window.swiper?.destroy();
            window.swiper = null;
        }

        let virtual = homeslider.classList.contains('home-swiper') ? true : false;

        console.log(virtual);

        window.swiper = new Swiper('.default-swiper', {
            modules: [Navigation, Manipulation, EffectFade, Autoplay, Keyboard, Virtual, EffectCreative],
            freeMode: false,
            keyboard: {
                onlyInViewport: true,
            },
            direction: "horizontal",
            loop: true,
            autoplay: {
                delay: 1500,
            },
            lazyPreloaderClass: "placeholder",
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            effect: 'slide',
            lazyPreloadPrevNext: 2,
            virtual: {
                enabled: virtual,
                /*addSlidesAfter: 2,
                addSlidesBefore: 2,*/
            },
            speed: 1,
            on: {
                init: function () {
                    setColors("header", "home-swiper");

                    if (this.el.dataset.randomize === "true") {
                        function getRandomNumber(min, max) {
                            return Math.floor(Math.random() * (max - min + 1)) + min;
                        }

                        this.slideTo(getRandomNumber(0, this.slides.length), 0);
                    }

                    this.autoplay.stop();
                    console.log(homeslider.dataset.autoplay);
                    if (homeslider.dataset.autoplay === "1") {
                        this.autoplay.start();
                        if (document.body.getAttribute('id') === 'home') {
                            this.autoplay.stop();
                        }
                        if (document.body.dataset.template === 'project') {
                            this.autoplay.stop();
                        }
                        if (document.body.dataset.template === 'selection') {
                            this.autoplay.start();
                        }
                    }

                    this.el.addEventListener('click', function () {
                        window.swiper.autoplay.stop();
                        setTimeout(function () {
                            if (document.body.dataset.template === "home" || document.body.dataset.template === "selection") {
                                window.swiper.autoplay.start();
                            }
                        }, 10000)
                    });

                    const area = document.querySelector('.home-swiper');
                    if (area) {
                        const data = {
                            value: 1
                        };
                        const touchThreshold = 20;

                        const touchSweepInstance = new TouchSweep(area, data, touchThreshold);

                        const handleSwipe = (direction) => {
                            return (event) => {
                                event.preventDefault();
                                if (direction === 'left' || direction === 'up') {
                                    this.slideNext();
                                } else {
                                    this.slidePrev();
                                }
                                window.swiper.autoplay.stop();
                                setTimeout(() => {
                                    if (document.body.dataset.template === "home" || document.body.dataset.template === "selection") {
                                        window.swiper.autoplay.start();
                                    }
                                }, 10000);
                            };
                        };

                        area?.addEventListener('swipeleft', handleSwipe('left'));
                        area?.addEventListener('swiperight', handleSwipe('right'));
                        area?.addEventListener('swipeup', handleSwipe('up'));
                        area?.addEventListener('swipedown', handleSwipe('down'));

                    }


                },
                resize: function (){
                  this.update();
                  setColors("header", 'home-swiper');
                },
                afterInit: function () {
                    /* window.addEventListener('resize', resizePlaceholders);
                     resizePlaceholders();*/
                    /*this.mousewheel.disable();*/


                    setColors("header", 'home-swiper');
                    const legend = this.el.querySelector('.swiper-legend');
                    const activeSlide = this.el.querySelector('.swiper-slide-active');

                    if (activeSlide) {

                        const activeTitle = activeSlide.dataset.title;
                        const activeIndex = activeSlide.dataset.index;
                        const disableViewButton = activeSlide.dataset.hasViewButton;

                        const legendTitle = this.el.querySelector('.swiper-legend .slide-title');
                        const legendIndex = this.el.querySelector('.swiper-legend .slide-number');
                        const viewProject = this.el.querySelector('.swiper-legend .view-project');


                        if (legend) {
                            if (activeTitle === undefined || activeIndex === undefined) {
                                legend.style.display = "none";
                            } else {
                                legend.style.display = null;
                                legendTitle.innerHTML = activeTitle;
                                legendIndex.innerHTML = activeIndex;
                                if (disableViewButton === "true") {
                                    viewProject.style.display = "none";
                                } else {
                                    const url = activeSlide.querySelector('a').dataset.page;
                                    viewProject.style.display = null;
                                    viewProject.dataset.page = url;
                                }
                            }
                        }
                    }
                },
                slideChangeTransitionStart: function () {
                    const activeSlide = this.el.querySelector('.swiper-slide-active');
                    const videos = document.querySelectorAll('.swiper-slide video');

                    if (videos) {
                        videos.forEach((video) => {
                            video.play();
                        });
                    }

                    setColors("header", 'home-swiper');
                    const legend = this.el.querySelector('.swiper-legend');

                    if (activeSlide) {

                        const activeTitle = activeSlide.dataset.title;
                        const activeIndex = activeSlide.dataset.index;
                        const disableViewButton = activeSlide.dataset.hasViewButton;

                        const legendTitle = this.el.querySelector('.swiper-legend .slide-title');
                        const legendIndex = this.el.querySelector('.swiper-legend .slide-number');
                        const viewProject = this.el.querySelector('.swiper-legend .view-project');

                        if (legend) {
                            if (activeTitle === undefined || activeIndex === undefined) {
                                legend.style.display = "none";
                            } else {
                                legend.style.display = null;
                                legendTitle.innerHTML = activeTitle;
                                legendIndex.innerHTML = activeIndex;
                                if (disableViewButton === "true") {
                                    viewProject.style.display = "none";
                                } else {
                                    const url = activeSlide.querySelector('a').dataset.page;
                                    viewProject.style.display = null;
                                    viewProject.dataset.page = url;
                                }

                            }
                        }
                    }

                },
                slideChangeTransitionEnd: function () {
                    this.update(this.el.querySelector('.swiper-slide-active'));
                },
                keyPress: function (swiper, keyCode) {

                    if (keyCode == "32") {
                        this.slideNext();
                    }

                }
            }
        });
    }
}

