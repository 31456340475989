export const screenSaver = () => {
    let screenSaver = null;
    let count = 0;
    const delay = 180;
    let clockUpdateInterval = null;
    let update = setInterval(updateCounterAndScreenSaver, 1000);

    const resetCounterAndScreenSaver = () => {
        count = 0;
        if (screenSaver) {
            removeClock();
        }
    };

    // Combine click and keyup events
    document.addEventListener('click', resetCounterAndScreenSaver);
    document.addEventListener('keyup', resetCounterAndScreenSaver);
    document.addEventListener('mousemove', () => count = 0);

    function updateCounterAndScreenSaver() {
        count++;
        if (count >= delay && !screenSaver) {
            createClock();
            count = 0; // Reset count after activating screensaver
        }
    }

    function createClock() {
        screenSaver = document.createElement('div');
        screenSaver.className = "screen-saver";
        screenSaver.innerHTML = `
            <div class='screen-saver-wrapper'>
                <p class='location'>PARIS<span class='country'>(FR)</span></p>
                <div id='clock'></div>
            </div>`;

        document.documentElement.prepend(screenSaver);
        setTimeout(() => {
            document.documentElement.classList.add('screen-saver-on');
            screenSaver.classList.add("show");

            window.swiper?.autoplay.stop();

        }, 1000);

        updateClock(); // Initialise l'horloge immédiatement sans attendre le prochain tick
        clockUpdateInterval = setInterval(updateClock, 1000); // Étape 2 : Démarrage de l'intervalle et stockage de sa référence
    }

    function removeClock() {
        if (screenSaver) {
            document.documentElement.classList.remove('screen-saver-on');
            screenSaver.classList.remove("show");
            screenSaver.addEventListener("transitionend", () => {
                screenSaver.remove()
                screenSaver = null;
                clearInterval(clockUpdateInterval);
            });

        }
    }

    function updateClock() {
        const clockElement = document.getElementById('clock');
        if (!clockElement) return; // Guard clause if clockElement does not exist

        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        clockElement.innerHTML = `
            <span class='hours'>${hours}</span><span class="colon">:</span>
            <span class='minutes'>${minutes}</span><span class="colon">:</span>
            <span class='seconds'>${seconds}</span>`;
    }
};